import axios from 'axios'
import config from './config'
import authStore from '../store/modules/auth'
import router from 'src/router'
import { correctApiBaseUrl } from '../utilities/api'

const multipartDataURLs = [
  '/upload/user/image',
  '/recording/media',
  '/callflow',
  '/account/createUser/account_user'
]

const errorStatus = [401]

const envURL = correctApiBaseUrl()
const apiUrl = new URL(envURL)
const hostname = window.location.hostname.split('.')
const baseURL = envURL
// if (hostname.length > 3) {
//   baseURL = `${apiUrl.protocol}//${hostname[0]}.${apiUrl.hostname}`
// }

const instance = axios.create({
  baseURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

instance.interceptors.request
  .use(
    (config) => {
      const user = authStore.state.currentUser
      const token = user ? user.token : null

      // console.log(config, 'config')
      if (token) {
        config.headers.Authorization = 'Bearer' + ' ' + token

        if (multipartDataURLs.includes(config.url) || (config.url.endsWith('/image') && config.method === 'post')) {
          config.headers.Accept = '*/*'
          config.headers['Content-Type'] = 'multipart/form-data'
        }
      }

      return config
    },
    (err) => {
      return Promise.reject(err)
    })

instance.interceptors.response
  .use(
    (res) => {
      return res
    },
    (err) => {
      const status = err.response.status
      if (errorStatus.includes(status) && router.currentRoute.path !== '/login' && router.currentRoute.name !== 'Confirmation') {
        router.push({ path: '/login' })
        authStore.actions.logout()
      }

      return Promise.reject(err)
    })

export default instance
