import axios from 'src/_services/axios-configs'

const state = {}

const getters = {}

const mutations = {}

const actions = {
  getAdminCompany (_, params = {}) {
    return new Promise((resolve, reject) => {
      const url = '/super_admin/company/list'

      axios.get(url, { params })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  changeAdminStatus (_, { uuid, status }) {
    return new Promise((resolve, reject) => {
      const url = `/super_admin/company/status/${uuid}`

      axios.patch(url, { status })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  changeAdminWhitelabel (_, { uuid, whitelabel }) {
    return new Promise((resolve, reject) => {
      const url = `/super_admin/company/${uuid}/whitelabel/${whitelabel}`

      axios.post(url, { status })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  setAdminCustomDomain (_, { uuid, customDomain }) {
    return new Promise((resolve, reject) => {
      const url = `/super_admin/company/${uuid}/custom_domain`

      axios.post(url, { customDomain })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  getAdminCompanyUsers (_, { accountUuid, params = {} }) {
    return new Promise((resolve, reject) => {
      const url = `/super_admin/company/users/list/${accountUuid}`

      axios.get(url, { params })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  changeAdminUserStatus (_, { uuid, status }) {
    return new Promise((resolve, reject) => {
      const url = `/super_admin/user-status/${uuid}`

      axios.post(url, { status })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  getAdminPayments (_, params = {}) {
    return new Promise((resolve, reject) => {
      const url = '/super_admin/payment/list'

      axios.get(url, { params })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  getAdminInvoices (_, params = {}) {
    return new Promise((resolve, reject) => {
      const url = '/super_admin/stripe_invoice/list'

      axios.get(url, { params })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  getAdminDIDs (_, params = {}) {
    return new Promise((resolve, reject) => {
      const url = '/super_admin/did/list'

      axios.get(url, { params })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  },
  adminAssignDID (_, { accountUuid, payload }) {
    return new Promise((resolve, reject) => {
      const url = `/did/${accountUuid}/assign`

      axios.post(url, payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err.response.data)
        })
    })
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}
