<template>
  <div id="app">
    <component :is="layout">
      <router-view/>
    </component>
  </div>
</template>

<script>
import MainLayout from './layouts/MainLayout'
import EmptyLayout from './layouts/EmptyLayout'
import AdminLayout from './layouts/AdminLayout'
import { isWhitelabel } from '@/utilities/api'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'App',
  components: {
    MainLayout,
    EmptyLayout,
    AdminLayout
  },
  computed: {
    ...mapGetters([
      'currentCompany'
    ]),
    layout () {
      return (this.$route.meta.layout || 'empty') + '-layout'
    }
  },
  created () {
    if (isWhitelabel) {
      const domain = this.currentCompany ? this.currentCompany.custom_domain : window.location.hostname
      this.loadDetails(domain)
    }
  },
  methods: {
    ...mapActions(['loadDetails'])
  }
}
</script>

<style lang="scss">
  @import "scss/main.scss";
</style>
